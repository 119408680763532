import { formatBytes } from 'lib/utils';
import { UnitType } from 'metadata/enum';

/**
 * Formats the value as file size based on its unit type.
 * @param value The value to format.
 * @param unitType Unit type of the value.
 * @returns A string representation of the value as file size.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function formatFileSize(value: any, unitType: UnitType = UnitType.Byte): string {
  if (!value) return '0';

  let bytes = 0;
  switch (unitType) {
    case UnitType.Bit:
      bytes = Number(value) / 8;
      break;
    case UnitType.Byte:
      bytes = Number(value);
      break;
    case UnitType.Kilobyte:
      bytes = Number(value) * 1024;
      break;
    case UnitType.Megabyte:
      bytes = Number(value) * 1024 * 1024;
      break;
    case UnitType.Gigabyte:
      bytes = Number(value) * 1024 * 1024 * 1024;
      break;
    case UnitType.Terabyte:
      bytes = Number(value) * 1024 * 1024 * 1024 * 1024;
      break;
  }

  return formatBytes(bytes);
}
