import React from 'react';
import { Redirect } from 'react-router-dom';
import { hasRole, useAuth } from 'auth';

interface Props {
  children?: React.ReactNode;
  role?: string;
}

export const RoleGuard: React.FC<Props> = ({ children, role }: Props) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated || user == null || (role != undefined && !hasRole(user, role))) {
    // Redirect to main
    return <Redirect to="/" />;
  }

  return <>{children}</>;
};

export default RoleGuard;
