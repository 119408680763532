import React from 'react';
import { Box, LinearProgress, makeStyles, Typography } from '@material-ui/core';

interface Props {
  title?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    alignSelf: 'center',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3),
  },
}));

const LoadingScreen: React.FC<Props> = ({ title }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box width={400}>
        {title && (
          <Box pb={1}>
            <Typography variant="subtitle2">{title}</Typography>
          </Box>
        )}
        <LinearProgress />
      </Box>
    </div>
  );
};

export default LoadingScreen;
