import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Tab, Tabs as MuiTabs } from '@material-ui/core';
import { TabItem } from 'types';

interface Props {
  tabs: Array<TabItem>;
  value?: any;
  onChange: (e: React.ChangeEvent<any>, newValue: any) => void;
}

export function Tabs({ tabs, value, onChange }: Props): React.ReactElement {
  return tabs && tabs.length > 0 ? (
    <Box pb={3}>
      <MuiTabs value={value} indicatorColor="primary" textColor="primary" onChange={onChange}>
        {tabs.map((tab) => (
          <Tab key={tab.label} label={tab.label} value={tab.value} />
        ))}
      </MuiTabs>
      <Divider />
    </Box>
  ) : (
    <></>
  );
}

Tabs.propTypes = {
  tabs: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default Tabs;
