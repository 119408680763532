import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/core';
import { CssBaseline } from '@material-ui/core';
import { AuthGuard } from 'auth/AuthGuard';
import { SnackbarProvider } from 'notistack';
import { createCustomTheme } from 'themes';
import { DialogProvider } from 'contexts/DialogContext';
import { ApplicationReady } from 'components/ApplicationReady';
import { DialogContainer } from 'components/DialogContainer';
import MainLayout from 'layouts/MainLayout';
import useSettings from 'hooks/useSettings';
import { AppRoutes } from 'routes';

const history = createBrowserHistory();

const App: React.FC = () => {
  const { settings } = useSettings();
  const theme = createCustomTheme({
    theme: settings.theme,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
  });

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router history={history}>
          <AuthGuard>
            <ApplicationReady>
              <SnackbarProvider>
                <DialogProvider>
                  <MainLayout>
                    <AppRoutes />
                  </MainLayout>
                  <DialogContainer />
                </DialogProvider>
              </SnackbarProvider>
            </ApplicationReady>
          </AuthGuard>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
