import { lazy } from 'react';
import { Module } from 'types';
import { faPenField } from '@fortawesome/pro-regular-svg-icons';
import DefaultLayout from 'layouts/DefaultLayout';

const form: Module = {
  name: 'Form',
  icon: 'assignment',
  role: 'Administrator',
  layout: DefaultLayout,
  features: [
    {
      name: 'Form',
      component: lazy(() => import('modules/Form/Form')),
      icon: faPenField,
    },
  ],
};

export default form;
