import React from 'react';
import { appRoutes } from './appRoutes';
import { errorRoutes } from './errorRoutes';
import { renderRoutes } from './renderRoutes';
import { RouteWithContext } from './Route';

export { getEntityRouteValues } from './entityRoutes';
export * from './path';
export { renderRoutes } from './renderRoutes';
export * from './Route';

export const routes: Array<RouteWithContext> = [...errorRoutes, ...appRoutes];

export const AppRoutes: React.FC = () => renderRoutes(routes);
