import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import Fieldset from 'components/Fieldset';
import Select from 'components/Select';
import Switch from 'components/Switch';
import SubmitButton from 'components/SubmitButton';
import ThemePreview from 'components/ThemePreview';
import useSettings from 'hooks/useSettings';
import { getThemeNames } from 'themes';

const Security: React.FC = () => {
  const { t } = useTranslation(['account', 'messages']);
  const { settings, saveSettings } = useSettings();
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<any>({ mode: 'onBlur' });

  const themeNames = getThemeNames();
  const items = themeNames.map((t) => ({ label: t, value: t }));
  const watched = watch();
  const themeProps = Object.assign(
    {},
    {
      ...settings,
    },
    watched
  );

  // Handler
  const onSubmit = (data: any): void => {
    saveSettings({ ...settings, ...data });
  };
  const submit = (e: React.FormEvent<HTMLFormElement>): Promise<void> => handleSubmit(onSubmit)(e);

  return (
    <form onSubmit={submit} noValidate>
      <Fieldset title={t('Theme.title')}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Select
              control={control}
              name="theme"
              label={t('Theme.properties.theme')}
              defaultValue={settings.theme}
              error={Object.prototype.hasOwnProperty.call(errors, 'theme')}
              items={items}
              required
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <ThemePreview {...themeProps} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Switch
              control={control}
              name="responsiveFontSizes"
              label={t('Theme.properties.responsiveFontSizes')}
              defaultValue={settings.responsiveFontSizes}
              error={Object.prototype.hasOwnProperty.call(errors, 'responsiveFontSizes')}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Switch
              control={control}
              name="roundedCorners"
              label={t('Theme.properties.roundedCorners')}
              defaultValue={settings.roundedCorners}
              error={Object.prototype.hasOwnProperty.call(errors, 'roundedCorners')}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <SubmitButton color="secondary" />
          </Grid>
        </Grid>
      </Fieldset>
    </form>
  );
};

export default Security;
