import clsx from 'clsx';
import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(6),
    minHeight: '100%',
  },
  relative: {
    position: 'relative',
  },
}));

export function CenteredLayout({
  children,
  relative,
}: InferProps<typeof CenteredLayout.propTypes>): React.ReactElement {
  const classes = useStyles();
  return <Box className={clsx(classes.root, { [classes.relative]: relative })}>{children}</Box>;
}

CenteredLayout.propTypes = {
  children: PropTypes.node,
  relative: PropTypes.bool,
};

export default CenteredLayout;
