import clsx from 'clsx';
import snakeCase from 'lodash/snakeCase';
import React from 'react';
import { Avatar, Box, Card, Icon, Typography, makeStyles } from '@material-ui/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from './Label';

interface StatCardProps {
  className?: string;
  difference?: number;
  icon?: IconDefinition | string;
  title: string;
  value?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    marginLeft: theme.spacing(1),
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    height: 48,
    width: 48,
  },
}));

const StatCard: React.FC<StatCardProps> = ({
  className,
  difference,
  icon,
  title,
  value,
}: StatCardProps) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)}>
      <Box flexGrow={1}>
        <Typography component="h3" gutterBottom variant="overline" color="textSecondary">
          {title}
        </Typography>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Typography variant="h3" color="textPrimary">
            {value || '-'}
          </Typography>
          {difference != null && (
            <Label className={classes.label} color={difference >= 0 ? 'success' : 'error'}>
              {difference > 0 ? '+' : ''}
              {difference}%
            </Label>
          )}
        </Box>
      </Box>
      {icon && (
        <Avatar className={classes.avatar}>
          {typeof icon === 'string' ? (
            <Icon>{snakeCase(icon)}</Icon>
          ) : (
            <FontAwesomeIcon fixedWidth icon={icon} />
          )}
        </Avatar>
      )}
    </Card>
  );
};

export default StatCard;
