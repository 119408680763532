import Joi from 'joi';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { Box, Grid, TextField } from '@material-ui/core';

import { useAuth, useUpdateUserMutation } from 'auth';
import Fieldset from 'components/Fieldset';
import SubmitButton from 'components/SubmitButton';
import SubmitStateAlert from 'components/SubmitStateAlert';

import Privacy from './Privacy';

const validationSchema = Joi.object({
  userName: Joi.string().min(3).max(64).required(),
  email: Joi.string()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .required(),
  phoneNumber: Joi.string().empty(''),
});

const Account: React.FC = () => {
  const { t } = useTranslation(['account', 'defaults', 'messages']);
  const { control, handleSubmit, formState } = useForm<any>({
    mode: 'onBlur',
    resolver: joiResolver(validationSchema),
  });
  const { user } = useAuth();
  const [update, { isError, isLoading, isSuccess }] = useUpdateUserMutation();

  // Handler
  const submit = (e: React.FormEvent<HTMLFormElement>): Promise<void> => handleSubmit(update)(e);

  return (
    <>
      <form onSubmit={submit} noValidate>
        <Fieldset title={t('Account.title')}>
          <Grid container spacing={3}>
            <SubmitStateAlert
              grid={12}
              isError={isError}
              isInvalid={formState.errors.length > 0}
              isLoading={isLoading}
              isSuccess={isSuccess}
            />
            <Grid item xs={12} md={12}>
              <Controller
                name="userName"
                control={control}
                defaultValue={user?.userName}
                render={({ field, fieldState }) => (
                  <TextField
                    required
                    label={t('Account.properties.userName')}
                    helperText={t('Account.propertyDescriptions.userName', { defaultValue: '' })}
                    error={fieldState.invalid}
                    fullWidth
                    size="small"
                    variant="outlined"
                    inputProps={{ maxLength: 64 }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="email"
                control={control}
                defaultValue={user?.email}
                render={({ field, fieldState }) => (
                  <TextField
                    required
                    label={t('Account.properties.email')}
                    helperText={t('Account.propertyDescriptions.email', { defaultValue: '' })}
                    error={fieldState.invalid}
                    fullWidth
                    size="small"
                    variant="outlined"
                    inputProps={{ maxLength: 255 }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="phoneNumber"
                control={control}
                defaultValue={user?.phoneNumber}
                render={({ field, fieldState }) => (
                  <TextField
                    label={t('Account.properties.phoneNumber')}
                    helperText={t('Account.propertyDescriptions.phoneNumber', { defaultValue: '' })}
                    error={fieldState.invalid}
                    fullWidth
                    size="small"
                    variant="outlined"
                    inputProps={{ maxLength: 255 }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <SubmitButton
                color={formState.isDirty && formState.isValid ? 'primary' : 'default'}
                inProgress={isLoading}
              />
            </Grid>
          </Grid>
        </Fieldset>
      </form>
      <Box pt={3}>
        <Privacy />
      </Box>
    </>
  );
};

export default Account;
