import i18n from 'i18next';
import React, { PropsWithChildren } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useGetLocalesQuery } from 'localization';
import { useGetMetadataQuery } from 'metadata';
import { useGetSchemaQuery } from 'schema';
import ApplicationError from './ApplicationError';
import LoadingScreen from './LoadingScreen';

export const ApplicationReady: React.FC<PropsWithChildren<any>> = (props) => {
  const { ready: translationsReady, t } = useTranslation(['application', 'ApplicationReady']);

  const {
    error: localesError,
    isError: localesIsError,
    isSuccess: localesIsSuccess,
  } = useGetLocalesQuery({ languages: i18n.languages as string[] });
  const {
    error: metadataError,
    isError: metadataIsError,
    isSuccess: metadataIsSuccess,
  } = useGetMetadataQuery();
  const {
    error: schemaError,
    isError: schemaIsError,
    isSuccess: schemaIsSuccess,
  } = useGetSchemaQuery();

  const isError = localesIsError || metadataIsError || schemaIsError;
  const isReady = localesIsSuccess && metadataIsSuccess && schemaIsSuccess && translationsReady;
  const error = localesError || metadataError || schemaError;

  if (isError) {
    return <ApplicationError error={error || ''} />;
  }

  return isReady ? props.children : <LoadingScreen title={t('ApplicationReady:title')} />;
};

ApplicationReady.propTypes = {
  children: PropTypes.node,
};

export default ApplicationReady;
