import Joi from 'joi';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import PageTitle from 'components/PageTitle';

interface FormProps {
  onBackToLogin?: () => void;
}

const validationSchema = Joi.object({
  email: Joi.string().required(),
});

const ForgotPassword: React.FC<FormProps> = ({ onBackToLogin }) => {
  const { t } = useTranslation('account');
  const { control, handleSubmit } = useForm<any>({
    mode: 'onBlur',
    resolver: joiResolver(validationSchema),
  });

  // Handler
  const onError = (errors: any): void => {
    console.error('Form errors', errors);
  };
  const onSubmit = (data: any): void => {
    console.log('Form submit', data);
  };
  const submit = (e: React.FormEvent<HTMLFormElement>): Promise<void> =>
    handleSubmit(onSubmit, onError)(e);

  return (
    <>
      <Box pb={6}>
        <PageTitle
          title={t('ForgotPassword.title')}
          subtitle={t('ForgotPassword.text')}
          overline="Admin"
        />
      </Box>
      <form onSubmit={submit} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  required
                  label={t('ForgotPassword.email')}
                  error={fieldState.invalid}
                  fullWidth
                  size="small"
                  variant="outlined"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Button variant="contained" color="primary" type="submit">
                  {t('ForgotPassword.reset')}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="text" onClick={onBackToLogin}>
                  {t('ForgotPassword.backToLogin')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

ForgotPassword.propTypes = {
  onBackToLogin: PropTypes.func,
};

export default ForgotPassword;
