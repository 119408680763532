import kebabCase from 'lodash/kebabCase';
import trim from 'lodash/trim';
import { StringMap } from 'types';

/**
 * Safely encodes a path component and converts it to kebab case.
 * @remarks
 * Any preceding or trailing slashes will be trimmed.
 */
export function encodeComponent(comp: string): string {
  return comp ? encodeURIComponent(kebabCase(trim(comp, '/'))) : comp;
}

/**
 * Safely concatenates path components.
 * @remarks
 * Each empty, null or undefined argument will be ignored.
 * Preserves the preceding slash, all other duplice slashes will be deleted.
 * @param args Each argument represents a path component.
 */
export function combinePath(...args: string[]): string {
  return (
    '/' +
    args
      .filter((s) => s != null && s !== '')
      // Trim all slashes except for the first argument if it starts with a relative protocol //
      .map((s, i) => (i === 0 && s.startsWith('//') ? '//' + trim(s, '/') : trim(s, '/')))
      // Filter strings of '/' or '//' which are empty after trimming
      .filter((s) => s !== '')
      .join('/')
  );
}

/**
 * Safely concatenates path components.
 * @remarks
 * Each empty, null or undefined argument will be ignored.
 * Duplice slashes will be deleted.
 * @param args Each argument represents a path component.
 */
export function combineUrl(...args: string[]): string {
  const url = combinePath(...args);
  return url.indexOf('://') > 0 || url.indexOf('//') === 0
    ? url.slice(1) // Remove preceding slash from absolute url
    : url; // Return relateive url as it is
}

/**
 * Safely removes path components.
 * @remarks
 * Each empty, null or undefined argument will be ignored.
 * Preserves the preceding slash, all other duplice slashes will be deleted.
 * @param path The path to reduce.
 * @param reduceBy The number of path components to delete from the end.
 */
export function reducePath(path: string, reduceBy = 1): string {
  const components = path ? path.split('/') : [];
  const precedingSlash = path && path.startsWith('/');
  if (components.length > 0 && reduceBy > 0) {
    const result = components
      .slice(0, -Math.min(reduceBy, components.length - 1))
      .filter((s) => s !== '')
      .join('/');
    return precedingSlash ? '/' + result : result;
  }
  return path;
}

/**
 * Generates the path for the specified error name or error number.
 */
export function errorPath(error: string | number, baseUrl = '/'): string {
  return combinePath(baseUrl, 'error', encodeComponent(String(error)));
}

/**
 * Generates the path for the specified module and feature. Empty arguments will be ignored.
 */
export function appPath(module = '', feature = '', baseUrl = '/'): string {
  return combinePath(baseUrl, encodeComponent(module), encodeComponent(feature));
}

/**
 * Generates the path for the specified module and feature.
 */
export function featurePath(module: string, feature: string, baseUrl = '/'): string {
  return appPath(encodeComponent(module), encodeComponent(feature), baseUrl);
}

/**
 * Generates the path for the specified module.
 */
export function modulePath(module: string, baseUrl = '/'): string {
  return appPath(encodeComponent(module), '', baseUrl);
}

export interface EntityPathOptions {
  baseUrl?: string;
  command?: string;
  key?: string;
  feature?: string;
  module?: string;
}

/**
 * Generates the path for the specified entity resource.
 * @param entity Name of the entity to create path for.
 * @param options Optional path options.
 * @param keyValues Optional parameter values.
 * @remarks
 * Ignores entity path component if entity name and feature name are the same, e.g.
 * 'images', 'media', 'images' -> '/media/images'
 */
export function entityPath(entity: string, options?: EntityPathOptions): string {
  const entityEncoded = encodeComponent(entity);
  if (options) {
    const path = appPath(options.module, options.feature, options.baseUrl);
    const pathComp = path.split('/');

    // Add entity name to path.
    // Skip if the last component of the path is equal to it.
    if (pathComp.length === 0 || pathComp[pathComp.length - 1] !== entityEncoded) {
      pathComp.push(entityEncoded);
    }

    // Add components for key and command
    if (options.key) {
      pathComp.push(`:${options.key}`);
    }
    if (options.command) {
      pathComp.push(encodeComponent(options.command));
    }

    // Merge path components together
    return combinePath(...pathComp);
  }

  return combinePath(entityEncoded);
}

/**
 * Replaces all parameter keys in a path by values.
 * @param path The path to replace parameter keys by values.
 * @param keyValues The key value pairs.
 * @example
 *   pathValues('api/:version', [['version', 'v2']]) -> 'api/v2'
 */
export function pathValues(path: string, keyValues: StringMap): string {
  return path
    ? combinePath(
        ...path
          .split('/')
          .map((comp) =>
            comp.startsWith(':') && comp.length > 1 ? keyValues[comp.slice(1)] || comp : comp
          )
      )
    : path;
}
