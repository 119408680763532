import React, { useEffect, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useLocation } from 'react-router-dom';
import { SwipeableDrawer, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ApplicationBar from 'components/ApplicationBar';
import ApplicationMenu from 'components/ApplicationMenu';

const NAV_SIDEBAR_WIDTH = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  drawer: {
    background: theme.palette.primary.main,
  },
  header: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: NAV_SIDEBAR_WIDTH,
    },
  },
  nav: {
    width: NAV_SIDEBAR_WIDTH,
  },
  main: {
    display: 'flex',
    flex: '1 1 auto',
    position: 'relative',
    boxSizing: 'border-box',
    padding: `${theme.spacing(8)}px 0 0`,
    minHeight: '100%',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: NAV_SIDEBAR_WIDTH,
    },
  },
}));

export function MainLayout({
  children,
}: InferProps<typeof MainLayout.propTypes>): React.ReactElement {
  const classes = useStyles();
  const location = useLocation();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [open, setOpen] = useState<boolean>(false);

  const toggleDrawer = (newState: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpen(newState);
  };

  // Close temporary drawer when location changes
  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  return (
    <div className={classes.root}>
      <ApplicationBar
        className={classes.header}
        hideMenuToggle={lgUp}
        onToggleMenu={toggleDrawer(!open)}
      />
      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        PaperProps={{ className: classes.drawer }}
        variant={lgUp ? 'permanent' : 'temporary'}
      >
        <ApplicationMenu className={classes.nav} />
      </SwipeableDrawer>
      <main className={classes.main}>{children}</main>
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
