import React from 'react';
import PropTypes from 'prop-types';
import { Action } from 'types';
import { ActionItem } from './ActionItem';

interface ActionListProps {
  disableEdge?: boolean;
  items: Array<Action>;
}

export function ActionList({ disableEdge, items }: ActionListProps): React.ReactElement {
  if (!items || items.length === 0) {
    return <></>;
  }

  return (
    <>
      {items.map((item, i) => (
        <ActionItem
          key={item.id}
          edge={
            disableEdge
              ? false
              : item.edge || (i === items.length - 1 ? 'end' : i === 0 ? 'start' : false)
          }
          {...item}
        />
      ))}
    </>
  );
}

ActionList.propTypes = {
  items: PropTypes.array,
};

export default ActionList;
