import clsx from 'clsx';
import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

interface Props {
  label: string;
  valid?: boolean;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: 14,
  },
  icon: { color: theme.palette.text.secondary },
  text: {
    marginLeft: 8,
    color: theme.palette.text.secondary,
  },
  error: { color: theme.palette.error.main },
  valid: { color: theme.palette.success.main },
}));

const FieldRequirement: React.FC<Props> = ({ label, valid }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      {valid ? (
        <CheckCircleOutlineIcon className={clsx(classes.icon, classes.valid)} fontSize="small" />
      ) : (
        <HighlightOffIcon
          className={clsx(classes.icon, { [classes.error]: valid === false })}
          fontSize="small"
        />
      )}
      <Typography
        className={clsx(classes.text, {
          [classes.error]: valid === false,
          [classes.valid]: valid,
        })}
        variant="caption"
      >
        {label}
      </Typography>
    </div>
  );
};

export default FieldRequirement;
