import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
  },
  logo: {
    fill: theme.palette.secondary.main,
    height: 16,
  },
}));

const Logo: React.FC = () => {
  const classes = useStyles();

  return (
    <RouterLink className={classes.root} to="/">
      <svg
        className={classes.logo}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 78.04896 17.00787"
      >
        <rect x="16.38956" y="0.30874" width="3.90252" height="16.3904" />
        <rect x="38.24702" y="0.30874" width="3.90245" height="16.3904" />
        <polygon points="0 16.699 14.049 16.699 14.049 12.797 3.902 12.797 3.902 10.456 14.049 10.456 14.049 6.554 0 6.554 0 16.699" />
        <rect y="0.30874" width="14.04895" height="3.90249" />
        <polygon points="22.243 0.309 22.243 4.211 27.316 4.211 27.316 16.699 31.219 16.699 31.219 4.211 36.292 4.211 36.292 0.309 22.243 0.309" />
        <path
          d="M57.21016,3.44478l-6.632,0V19.8352l6.632,0a8.19523,8.19523,0,1,0,0-16.39046Zm0,12.488-2.72956,0V7.34729l2.72956,0a4.29274,4.29274,0,1,1,0,8.58548Z"
          transform="translate(-6.08846 -3.13606)"
        />
        <path
          d="M66.96643,11.64a8.58556,8.58556,0,0,0,7.41475,8.50394v-4.377a4.28868,4.28868,0,0,1,0-8.25388v-4.377A8.58557,8.58557,0,0,0,66.96643,11.64Z"
          transform="translate(-6.08846 -3.13606)"
        />
        <path
          d="M76.72266,3.13606v4.377a4.28868,4.28868,0,0,1,0,8.25388v4.377a8.58394,8.58394,0,0,0,0-17.00788Z"
          transform="translate(-6.08846 -3.13606)"
        />
      </svg>
    </RouterLink>
  );
};

export default Logo;
