import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';

export type MessageType = 'success' | 'info' | 'warning' | 'error';

interface Props {
  error?: string;
  grid?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  isError?: boolean;
  isInvalid?: boolean;
  isLoading?: boolean;
  isSuccess?: boolean;
}

const SubmitStateAlert = ({
  error,
  grid,
  isError,
  isInvalid,
  isLoading,
  isSuccess,
}: Props): React.ReactElement | null => {
  const { t } = useTranslation('messages');
  let message = '';
  let severity: Color = 'error';

  if (isLoading) {
    return null;
  }

  if (isError) {
    message = error || t('error.submitFailed');
  } else if (isSuccess) {
    message = t('success.submitSuccess');
    severity = 'success';
  } else if (isInvalid) {
    message = t('error.invalidForm');
  }

  if (!message) {
    return null;
  }

  return grid ? (
    <Grid item xs={grid}>
      <Alert severity={severity}>{message}</Alert>
    </Grid>
  ) : (
    <Alert severity={severity}>{message}</Alert>
  );
};
export default SubmitStateAlert;
