import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { DialogContext } from 'contexts/DialogContext';

export interface ConfirmDialogProps {
  title: string;
  text: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const ConfirmDialog = ({
  title,
  text,
  onConfirm,
  onCancel,
}: ConfirmDialogProps): React.ReactElement => {
  const { close } = useContext(DialogContext);
  const { t } = useTranslation('ConfirmDialog');

  const handleConfirm = (): void => {
    if (onConfirm) {
      onConfirm();
    }
    close();
  };

  const handleCancel = (): void => {
    if (onCancel) {
      onCancel();
    }
    close();
  };

  return (
    <Dialog
      open
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-text"
    >
      <DialogTitle id="alert-dialog-title" disableTypography>
        <Typography variant="h4">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-text">{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {t('cancel')}
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          autoFocus
          disableElevation
        >
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
