import { combineReducers, AnyAction } from '@reduxjs/toolkit';
import { api } from 'api';
import { actions as authActions, api as authApi, reducer as auth } from 'auth';
import { reducer as configuration } from 'configuration';
import { reducer as localization } from 'localization';
import { reducer as metadata } from 'metadata';
import { reducer as schema } from 'schema';

const combinedReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth,
  [authApi.reducerPath]: authApi.reducer,
  configuration,
  localization,
  metadata,
  schema,
});

export type RootState = ReturnType<typeof combinedReducer>;

export const rootReducer = (state: RootState | undefined, action: AnyAction): RootState =>
  combinedReducer(action.type === authActions.logout.toString() ? undefined : state, action);

export default rootReducer;
