import React from 'react';
import PropTypes from 'prop-types';
import { Control, Controller } from 'react-hook-form';
import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

export interface CheckboxMenuItem {
  label: string;
  value: string;
}

interface CheckboxProps {
  control?: Control;
  defaultValue?: any;
  disabled?: boolean;
  error?: any;
  label: string;
  name: string;
  required?: boolean;
  props?: MuiCheckboxProps;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  error: {
    color: theme.palette.error.main,
  },
}));

export function Checkbox({
  control,
  defaultValue,
  disabled,
  error,
  label,
  name,
  props,
  required,
}: CheckboxProps): React.ReactElement {
  const classes = useStyles();
  const renderCheckbox = ({ field, fieldState }: any): JSX.Element => (
    <FormControlLabel
      control={<MuiCheckbox id={name} name={name} required={required} {...props} {...field} />}
      className={(fieldState?.error || error) && classes.error}
      disabled={disabled}
      label={label}
    />
  );

  // Material UI Checkbox and some other components don't work with react-hook-form (uncontrolled inputs),
  // that's why we use a react-hook-form controller instead the register method
  // Find more information here:
  //   https://github.com/react-hook-form/react-hook-form/issues/380
  //   https://stackoverflow.com/questions/63236951/how-to-use-material-ui-select-with-react-hook-form
  //   https://codesandbox.io/s/react-hook-form-v6-controller-qsd8r?file=/src/Mui.js
  return control ? (
    <Controller
      control={control}
      defaultValue={defaultValue || false}
      name={name}
      rules={{ required }}
      render={renderCheckbox}
    />
  ) : (
    renderCheckbox({ field: {}, fieldState: {} })
  );
}

Checkbox.propTypes = {
  control: PropTypes.any,
  disabled: PropTypes.bool,
  error: PropTypes.any,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  props: PropTypes.any,
  value: PropTypes.any,
};

export default Checkbox;
