import { useDispatch, useSelector } from 'store';
import { AuthState } from './types';
import { actions, selectAuth } from './slice';

interface State extends AuthState {
  logout: () => void;
  urlWithToken: (fileUrl: string) => string;
}

/**
 * A hook that provides state and functions for authentication and account management.
 * @param name Name of the entity.
 * @returns The translated entity metadata or it throws an error if entity not found.
 */
export function useAuth(): State {
  const dispatch = useDispatch();
  const state = useSelector(selectAuth);
  const logout = (): void => {
    dispatch(actions.logout());
  };

  const urlWithToken = (fileUrl: string): string => {
    const absoluteUrl =
      fileUrl == ''
        ? `${window.location.origin}/${fileUrl}`
        : fileUrl.startsWith('/')
        ? window.location.origin + fileUrl
        : fileUrl;
    const url = new URL(absoluteUrl);
    if (state.accessToken) url.searchParams.append('accessToken', state.accessToken);
    return url.toString();
  };

  return { ...state, logout, urlWithToken };
}

// NOTE: Don't use default export since this module will be re-exported in index.ts.
//       All the following modules will be undefined in that case.
// export default useAuth;
