import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';
import DefaultLayout from 'layouts/DefaultLayout';
import { ApiError, StringMap } from 'types';
import Error from './Error';

interface Props {
  error: StringMap | ApiError | string;
}

const ApplicationError: React.FC<Props> = ({ error }: Props) => {
  const { t } = useTranslation('ApplicationError');

  // TODO: Report error to API
  console.error(error);

  return (
    <DefaultLayout>
      <Container maxWidth="sm">
        <Error error={t('description')} />
      </Container>
    </DefaultLayout>
  );
};

export default ApplicationError;
