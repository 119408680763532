import { api as mainApi } from 'api';
import { StringMap } from 'types';

interface LocalesRequest {
  languages?: string[];
}

type LocalizationResponse = StringMap;

export const api = mainApi.injectEndpoints({
  endpoints: (build) => ({
    getLocales: build.query<LocalizationResponse, LocalesRequest | void>({
      query: (args) =>
        args && args.languages ? `locales?languages=${args.languages.join(',')}` : 'locales',
    }),
  }),
});

export const { useGetLocalesQuery } = api;
