import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PageTitle from 'components/PageTitle';

interface Props {
  children?: React.ReactNode;
  actions?: React.ReactNode;
  subtitle?: string;
  title: string;
}

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    alignItems: 'flex-end',
  },
});

const Header: React.FC<Props> = ({ actions, children, subtitle, title }: Props) => {
  const classes = useStyles();
  return (
    <Box pb={3}>
      <Grid container justifyContent="space-between" spacing={1}>
        <Grid item>
          <PageTitle title={title} subtitle={subtitle} />
        </Grid>
        {actions && (
          <Grid className={classes.actions} item>
            {actions}
          </Grid>
        )}
      </Grid>
      {children}
    </Box>
  );
};

Header.propTypes = {
  actions: PropTypes.node,
  children: PropTypes.node,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Header;
