import React, { Suspense } from 'react';
// import { Redirect, useLocation } from 'react-router-dom';
import { useInterval } from 'react-use';
import PropTypes from 'prop-types';
import { useAuth, useRefreshTokenMutation } from 'auth';
import LoadingScreen from 'components/LoadingScreen';
import Login from 'views/Account/Login';

const REFRESH_TOKEN_INTERVAL_MS = 5 * 60 * 1000;

interface AuthGuardProps {
  children?: React.ReactNode;
}

export const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [refreshToken] = useRefreshTokenMutation();

  // Refresh the token every 5 minutes
  useInterval(
    () => {
      // TODO: Show error message and logout if refresh token has already been used,
      //       because the token has been compromised.
      refreshToken();
    },
    isAuthenticated ? REFRESH_TOKEN_INTERVAL_MS : null
  );

  if (!isAuthenticated) {
    // // Redirect to login route if not already set
    // return ['/', '/login'].includes(location.pathname) ? <Login /> : <Redirect to="/login" />;
    return <Login />;
  }

  return <Suspense fallback={<LoadingScreen />}>{children}</Suspense>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
