import React from 'react';
import PropTypes from 'prop-types';

import { NavigationItem as INavigationItem } from 'types';
import { NavigationItem } from './NavigationItem';

interface NavigationProps {
  className?: string;
  items: Array<INavigationItem>;
  level?: number;
}

export const Navigation: React.FC<NavigationProps> = ({
  className,
  items,
  level = 0,
}: NavigationProps): React.ReactElement | null => {
  if (!items || items.length === 0) {
    return null;
  }

  return (
    <div className={className}>
      {items.map((item, index) => (
        <NavigationItem key={item.id || index} item={item} level={level} />
      ))}
    </div>
  );
};

Navigation.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
};

export default Navigation;
