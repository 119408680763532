import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Container, Drawer } from '@material-ui/core';
import Footer from 'components/Footer';
import Imprint from 'views/Imprint';
import PrivacyPolicy from 'views/PrivacyPolicy';

const Legal: React.FC = () => {
  const history = useHistory();
  return (
    <>
      <Switch>
        <Route path="/privacy-policy" exact>
          <Drawer open anchor="right" variant="temporary" onClose={() => history.push('/')}>
            <Container maxWidth="sm">
              <PrivacyPolicy compact />
            </Container>
          </Drawer>
        </Route>
        <Route path="/imprint" exact>
          <Drawer open anchor="right" variant="temporary" onClose={() => history.push('/')}>
            <Container maxWidth="sm">
              <Imprint compact />
            </Container>
          </Drawer>
        </Route>
      </Switch>
      <Footer />
    </>
  );
};

export default Legal;
