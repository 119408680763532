import { api as mainApi } from 'api';
import { ApiSchema } from './types';

type SchemaResponse = ApiSchema;

export const api = mainApi.injectEndpoints({
  endpoints: (build) => ({
    getSchema: build.query<SchemaResponse, void>({
      query: () => 'schema',
    }),
  }),
});

export const { useGetSchemaQuery } = api;
