import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import useBreadcrumbs from 'hooks/useBreadcrumbs';

interface PageTitleProps {
  overline?: string;
  subtitle?: string;
  title?: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ overline, subtitle, title }: PageTitleProps) => {
  const { crumbs } = useBreadcrumbs();
  const moduleTitle = crumbs && crumbs.length > 0 ? crumbs[0] : undefined;
  const featureTitle = crumbs && crumbs.length > 1 ? crumbs[1] : undefined;

  return (
    <>
      {(overline || moduleTitle) && (
        <Typography variant="overline">{overline || moduleTitle}</Typography>
      )}
      <Typography variant="h2">{title || featureTitle}</Typography>
      {subtitle && (
        <Box pt={1}>
          <Typography variant="subtitle2">{subtitle}</Typography>
        </Box>
      )}
    </>
  );
};

PageTitle.propTypes = {
  overline: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default PageTitle;
