import React, { useContext } from 'react';
import { DialogContext } from 'contexts/DialogContext';

export const DialogContainer = (): React.ReactElement | null => {
  const { component, props } = useContext(DialogContext);
  const DialogComponent = component as React.ComponentType;

  return component ? <DialogComponent {...props} /> : null;
};

export default DialogContainer;
