import { lazy } from 'react';
import { Module } from 'types';
import { faUsers, faUsersGear } from '@fortawesome/pro-regular-svg-icons';
import DefaultLayout from 'layouts/DefaultLayout';

const administration: Module = {
  name: 'Administration',
  icon: 'settings',
  role: 'Administrator',
  layout: DefaultLayout,
  features: [
    // {
    //   name: 'Configuration',
    //   component: lazy(() => import('./Configuration')),
    // },
    {
      name: 'Role',
      component: lazy(() => import('modules/Administration/Role')),
      icon: faUsersGear,
    },
    {
      name: 'User',
      component: lazy(() => import('modules/Administration/User')),
      icon: faUsers,
    },
  ],
};

export default administration;
