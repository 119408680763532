import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { faGaugeLow } from '@fortawesome/pro-regular-svg-icons';

import application from 'application';
import { hasRole, useAuth } from 'auth';
import { createModuleNavigationMap } from 'lib/navigation';
import Navigation from './Navigation';
import Logo from './Logo';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    minHeight: '100%',
    overflow: 'hidden',
  },
  header: {
    background: theme.palette.primary.main,
    padding: `0 ${theme.spacing(3)}px`,
    marginBottom: 11,
    minHeight: 56,
    [theme.breakpoints.up('sm')]: {
      minHeight: 64,
    },
  },
  content: {
    position: 'absolute',
    top: 56,
    left: 0,
    right: 0,
    bottom: 0,
    [theme.breakpoints.up('sm')]: {
      top: 64,
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: 27,
    },
  },
  featureList: {
    padding: 0,
  },
  moduleIcon: {
    marginRight: theme.spacing(1),
  },
  navigation: {
    color: theme.palette.primary.contrastText,
  },
}));

const Accordion = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    marginTop: '0 !important',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: 0,
    '&:before': {
      display: 'none',
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    color: alpha(theme.palette.primary.contrastText, 0.5),
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    display: 'block',
    padding: '0',
  },
})(MuiAccordionDetails);

const ApplicationMenu: React.FC<Props> = ({ children, className }: Props) => {
  const classes = useStyles();
  const { user } = useAuth();
  const { t } = useTranslation('application');

  // Create navigation items for each feature of each module
  const moduleNavigation = useMemo(
    () =>
      application.modules && user != null
        ? createModuleNavigationMap(application.modules, application.baseUrl, user)
        : new Map(),
    [user]
  );

  const dashboardItem = {
    id: 'dashboard',
    icon: faGaugeLow,
    label: 'Dashboard',
    href: '/',
  };

  return (
    <nav className={clsx(classes.root, className)}>
      <Box
        className={classes.header}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Logo />
      </Box>
      <div className={classes.content}>
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Accordion defaultExpanded>
            <AccordionDetails>
              <Navigation className={classes.navigation} items={[dashboardItem]} />
            </AccordionDetails>
          </Accordion>
          {application &&
            application.modules &&
            application.modules
              .filter((m) => hasRole(user, m.role))
              .map((module) => (
                <Accordion key={module.name} defaultExpanded>
                  <AccordionSummary aria-controls="module.name">
                    <Typography variant="overline">
                      {t(`modules.${module.name}.title`, { defaultValue: module.name })}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {moduleNavigation.has(module.name) && (
                      <Navigation
                        className={classes.navigation}
                        items={moduleNavigation.get(module.name)}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
          {children}
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default ApplicationMenu;
