import i18n from 'i18n';
import { UnitType } from 'metadata/enum';
import { StringMap } from 'types';

/**
 * Formats the value as number with unit type.
 * @param value The value to format.
 * @param unitType Unit type of the value.
 * @returns A string representation of the value.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function formatNumber(value: any, unitType: UnitType): string {
  if (!value) return '0';

  const units: StringMap = {
    //#region Absolute length units
    // Meter (m) is the fundamental unit of length in the International System of Units (SI).
    [UnitType.Meter]: 'm',
    // Kilometer (km). A kilometer represents 1000 m.
    [UnitType.Kilometer]: 'km',
    // Centimeter (cm). A centimeter represents 1/100 m.
    [UnitType.Centimeter]: 'cm',
    // Millimeter (mm). A millimeter represents 1/1000 m.
    [UnitType.Millimeter]: 'mm',
    // Micrometer (µm). A micrometer represents 1/1000 mm.
    [UnitType.Micrometer]: 'µm',
    // Nanometer (nm). A nanometer represents 1/1000 µm.
    [UnitType.Nanometer]: 'nm',
    // Inch (in). A inch represents 2.54 centimeters.
    [UnitType.Inch]: 'in',
    // Foot (ft). A foot represents 12 inches.
    [UnitType.Foot]: 'ft',
    // Yard (yd). A yard represents 3 feet.
    [UnitType.Yard]: 'yd',
    // Pixel (px). A pixel represents 1/96 of an inch.
    [UnitType.Pixel]: 'px',
    // Point (pt). A point represents 1/72 of an inch.
    [UnitType.Point]: 'pt',
    // Pica (pc). A pica represents 12 points.
    [UnitType.Pica]: 'pc',
    //#endregion

    //#region Relative units
    // Percentage (%)
    [UnitType.Percentage]: '%',
    // Font size of the element (em)
    [UnitType.FontSizeOfTheElement]: 'em',
    // Font size of the root element (rem)
    [UnitType.FontSizeOfTheRootElement]: 'rem',
    // X-Height of the element's font (ex)
    [UnitType.FontXHeight]: 'ex',
    //#endregion

    //#region Digital information units
    // Bit (bit).
    [UnitType.Bit]: 'bit',
    // Byte (B). A byte represents 8 bits.
    [UnitType.Byte]: 'Bytes',
    // Kilobyte (kB). A kilobyte represents 1024 bytes.
    [UnitType.Kilobyte]: 'KB',
    // Megabyte (MB). A megabyte represents 1024 kB.
    [UnitType.Megabyte]: 'MB',
    // Gigabyte (GB). A gigabyte represents 1024 MB.
    [UnitType.Gigabyte]: 'GB',
    // Terabyte (TB). A terabyte represents 1024 GB.
    [UnitType.Terabyte]: 'TB',
    //#endregion

    //#region Angle units
    // Degree (deg). There are 360 degrees in a full circle.
    [UnitType.Degree]: '°',
    // Gradian (grad). There are 400 gradians in a full circle.
    [UnitType.Gradian]: 'grad',
    // Radian (rad). There are 2? radians in a full circle.
    [UnitType.Radian]: 'rad',
    // Turn (turn). There is 1 turn in a full circle.
    [UnitType.Turn]: 'turn',
    //#endregion

    //#region Time units
    // Day (d). A day represents 24 hours.
    [UnitType.Day]: 'd',
    // Hour (h). An hour represents 60 minutes.
    [UnitType.Hour]: 'h',
    // Minute (min). A minute represents 60 seconds.
    [UnitType.Minute]: 'm',
    // Second (s) is the base unit of time in the International System of Unit (SI).
    [UnitType.Second]: 's',
    // Millisecond (ms). A millisecond represents 1/1000 s.
    [UnitType.Millisecond]: 'ms',
    // Microsecond (µs). A microsecond represents 1/1000 ms.
    [UnitType.Microsecond]: 'µs',
    // Nanosecond (ns). A nanosecond represents 1/1000 µs.
    [UnitType.Nanosecond]: 'ns',
    //#endregion

    //#region Frequency units
    // Hertz (Hz) is the SI unit of frequency defined as the number of cycles per second of a periodic phenomenon.
    [UnitType.Hertz]: 'Hz',
    // Kilohertz (kHz). A kilohertz represents 1000 Hz.
    [UnitType.Kilohertz]: 'kHz',
    // Megahertz (MHz). A megahertz represents 1000 kHz.
    [UnitType.Megahertz]: 'MHz',
    // Gigahertz (GHz). A gigahertz represents 1000 MHz.
    [UnitType.Gigahertz]: 'GHz',
    //#endregion

    //#region Resolution units
    // Dots per inch (dpi)
    [UnitType.DotsPerInch]: 'dpi',
    // Dots per centimeter (dpcm)
    [UnitType.DotsPerCentimeter]: 'dpcm',
    // Dots per pixel (dppx)
    [UnitType.DotsPerPixel]: 'dppx',
    //#endregion
  };

  const num = Number(value);
  const unit = units[String(unitType)];
  const result = new Intl.NumberFormat(i18n.language).format(num);
  return unit ? `${result} ${unit}` : result;
}
