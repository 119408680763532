import { lazy } from 'react';
import { Module } from 'types';
import {
  faClapperboardPlay,
  faListTree,
  faUserVneckHair,
  faHouseWindow,
} from '@fortawesome/pro-regular-svg-icons';
import DefaultLayout from 'layouts/DefaultLayout';

const support: Module = {
  name: 'Support',
  icon: 'person',
  role: 'Support',
  layout: DefaultLayout,
  features: [
    {
      name: 'Cooperative',
      component: lazy(() => import('modules/Support/Cooperative')),
      icon: faHouseWindow,
    },
    {
      name: 'Person',
      component: lazy(() => import('modules/Support/Person')),
      icon: faUserVneckHair,
    },
    {
      name: 'SupportVideo',
      component: lazy(() => import('modules/Support/SupportVideo')),
      icon: faClapperboardPlay,
    },
    {
      name: 'SupportVideoContent',
      component: lazy(() => import('modules/Support/SupportVideoContent')),
      icon: faListTree,
    },
    // {
    //   name: 'Invoice',
    //   component: lazy(() => import('modules/Support/Invoice')),
    //   icon: faMoneyCheckDollarPen,
    //   role: 'SaleNext',
    // },
    // {
    //   name: 'Address',
    //   group: 'person',
    //   component: lazy(() => import('modules/Support/Address')),
    // },
    // {
    //   name: 'Contact',
    //   group: 'person',
    //   component: lazy(() => import('modules/Support/Contact')),
    // },
    // {
    //   name: 'AddressType',
    //   group: 'configuration',
    //   component: lazy(() => import('modules/Support/AddressType')),
    // },
    // {
    //   name: 'ContactType',
    //   group: 'configuration',
    //   component: lazy(() => import('modules/Support/ContactType')),
    // },
  ],
};

export default support;
