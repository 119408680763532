import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid } from '@material-ui/core';
import { faChartUser, faCloudArrowDown, faMessageLines } from '@fortawesome/pro-regular-svg-icons';

import PageTitle from 'components/PageTitle';
// import DataList from './DataList';
// import Form from './Form';
// import BusinessDateTable from './BusinessDateTable';
// import IntlDateFormatTable from './IntlDateFormatTable';
import StatCard from './StatCard';
// import TypoExample from './TypoExample';
import Welcome from './Welcome';

const HomeView: React.FC = () => {
  const { t } = useTranslation('Home');

  // const titles = [
  //   'Lorem ipsum dolor',
  //   'Donec quam felis',
  //   'Phasellus viverra nulla',
  //   'Maecenas nec odio',
  //   'Elit tempor voluptua',
  //   'Aenean imperdiet',
  //   'Fusce vulputate eleifend',
  //   'Etiam rhoncus',
  //   'Cras ultricies mi',
  //   'Sea diam vero',
  // ];
  // const texts = [
  //   'Nullam dictum felis eu pede mollis pretium',
  //   'Aenean imperdiet',
  //   'Sed consequat leo eget bibendum',
  //   'Ultrices eos consetetur gubergren',
  //   'Nihil eros leo suscipit',
  //   'Sem est fringilla vitae laoreet',
  //   'Ultricies rhoncus quam',
  //   'Vivamus ex elementum risus',
  //   'Mollis sed nonummy id metus',
  //   'Tempus tellus eget condimentum rhoncus',
  // ];

  // const sales = [1, 2, 3, 4, 5].map((id) => ({
  //   id: id.toString(),
  //   title: titles[Math.floor(Math.random() * Math.floor(titles.length))],
  //   text: texts[Math.floor(Math.random() * Math.floor(texts.length))],
  // }));
  // const tasks = [1, 2, 3, 4, 5].map((id) => ({
  //   id: id.toString(),
  //   title: titles[Math.floor(Math.random() * Math.floor(titles.length))],
  //   text: texts[Math.floor(Math.random() * Math.floor(texts.length))],
  // }));

  return (
    <Container maxWidth="lg">
      <Box pb={3}>
        <PageTitle title={String(t('title'))} overline={String(t('overline'))} />
        <Welcome />
      </Box>
      <Grid container spacing={3}>
        <Grid item lg={4} sm={6} xs={12}>
          <StatCard title={String(t('visitors'))} value="41" difference={5} icon={faChartUser} />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <StatCard
            title={String(t('downloads'))}
            value="16"
            difference={0}
            icon={faCloudArrowDown}
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <StatCard title={String(t('posts'))} value="0" difference={0} icon={faMessageLines} />
        </Grid>
        {/* <Grid item lg={6} xs={12}>
          <IntlDateFormatTable />
        </Grid>
        <Grid item lg={6} xs={12}>
          <BusinessDateTable />
        </Grid> */}
        {/* <Grid item lg={6} xs={12}>
          <DataList
            title={String(t('tasks.title'))}
            buttonTitle={String(t('tasks.button'))}
            items={tasks}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <DataList
            title={String(t('sales.title'))}
            buttonTitle={String(t('sales.button'))}
            items={sales}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <TypoExample title={`${t('welcome')} ${user ? user.name : 'anonymous'}`} />
        </Grid>
        <Grid item lg={6} xs={12}>
          <Form title="User" />
        </Grid> */}
      </Grid>
    </Container>
  );
};

export default HomeView;
