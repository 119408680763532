import axios from 'axios';
import { AnyAction } from '@reduxjs/toolkit';
import { combineEpics, ofType } from 'redux-observable';
import { filter, ignoreElements, map, tap, Observable } from 'rxjs';
import { api } from './api';
import { actions } from './slice';
import { getXsrfToken } from './utils';

export const onLoginOrRefresh = (action$: Observable<AnyAction>): Observable<AnyAction> =>
  action$.pipe(
    filter(
      (action) =>
        api.endpoints.login.matchFulfilled(action) ||
        api.endpoints.refreshToken.matchFulfilled(action)
    ),
    map((action) => actions.setCredentials(action.payload))
  );

export const onChangeCredentials = (action$: Observable<AnyAction>): Observable<AnyAction> =>
  action$.pipe(
    ofType(actions.logout.toString(), actions.setCredentials.toString()),
    tap((action) => {
      if (action.payload?.user) {
        // Set XSRF token, if available
        const xsrfToken = getXsrfToken();
        if (xsrfToken) axios.defaults.headers.common['X-XSRF-TOKEN'] = xsrfToken;
      } else {
        // Remove storage token and authorization headers
        delete axios.defaults.headers.common['X-XSRF-TOKEN'];
      }
    }),
    ignoreElements()
  );

export const epic = combineEpics(onChangeCredentials, onLoginOrRefresh);
