import { formatDateTime } from 'lib/utils';
import { DataType, UnitType } from 'metadata/enum';
import { formatDuration } from './dateTime';
import { formatFileSize } from './digital';
import { formatNumber } from './number';

/**
 * Formats a value based on its data type and unit type.
 * @param value The value to format.
 * @param dataType The data type of the value.
 * @param unitType The unit type of the value.
 * @returns A string representation of the value as file size.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function format(value: any, dataType?: DataType, unitType?: UnitType): string {
  if (!value) return '';

  // Choose a formatter based on the data type.
  switch (dataType) {
    case DataType.DateTime:
      return formatDateTime(value, { dateStyle: 'medium', timeStyle: 'medium' });
    case DataType.Date:
      return formatDateTime(value, { dateStyle: 'medium' });
    case DataType.Time:
      return formatDateTime(value, { timeStyle: 'medium' });
    case DataType.Duration:
      return formatDuration(value, unitType);
    case DataType.FileSize:
      return formatFileSize(value, unitType);
  }

  if (typeof value === 'number' && unitType) return formatNumber(value, unitType);

  return String(value);
}
