import { useContext } from 'react';
import { DialogContext, DialogOpenFn } from 'contexts/DialogContext';

interface State {
  open: DialogOpenFn;
  close: () => void;
}

/**
 * Use dialog allows opening an application-wide dialog from everywhere.
 * @example
 * import ConfirmDialog from 'components/ConfirmDialog';
 * const { open } = useDialog();
 * open(ConfirmDialog, { title: '', text: '', onConfirm: () => {} });
 */
export function useDialog(): State {
  const { open, close } = useContext(DialogContext);
  return {
    open,
    close,
  };
}

export default useDialog;
