import React, { createContext, PropsWithChildren } from 'react';
import PropTypes from 'prop-types';

interface Props<T extends Record<string, any>> {
  context: T;
}

interface RouteContextState<T extends Record<string, any>> {
  context: T;
}

const initialRouteState: RouteContextState<any> = { context: {} };

const RouteContext = createContext<RouteContextState<any>>(initialRouteState);

export const RouteContextProvider = <T extends Record<string, any>>(
  props: PropsWithChildren<Props<T>>
): JSX.Element => {
  return <RouteContext.Provider value={{ ...props }}>{props.children}</RouteContext.Provider>;
};

RouteContextProvider.propTypes = {
  children: PropTypes.node,
};

export default RouteContext;
