import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, PropTypes } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  color?: PropTypes.Color;
  inProgress?: boolean;
  label?: string;
  onClick?: () => void;
}

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    display: 'inline-block',
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const SubmitButton = ({
  color = 'secondary',
  label,
  inProgress,
  onClick,
}: Props): React.ReactElement | null => {
  const classes = useStyles();
  const { t } = useTranslation('defaults');

  return (
    <div className={classes.buttonWrapper}>
      <Button
        color={color}
        disabled={inProgress}
        disableElevation
        type="submit"
        variant="contained"
        onClick={onClick}
      >
        {label ? label : t('commands.save')}
      </Button>
      {inProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
};
export default SubmitButton;
