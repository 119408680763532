import { lazy } from 'react';
import { Module } from 'types';
import { faMessages } from '@fortawesome/pro-regular-svg-icons';
import DefaultLayout from 'layouts/DefaultLayout';

const mail: Module = {
  name: 'Mail',
  icon: 'person',
  role: 'Mail',
  layout: DefaultLayout,
  features: [
    {
      name: 'MailboxMessage',
      component: lazy(() => import('modules/Mail/MailboxMessage')),
      icon: faMessages,
    },
    {
      name: 'MailboxAccount',
      group: 'configuration',
      component: lazy(() => import('modules/Mail/MailboxAccount')),
    },
    {
      name: 'MailboxLabel',
      group: 'configuration',
      component: lazy(() => import('modules/Mail/MailboxLabel')),
    },
    {
      name: 'MailTemplate',
      group: 'configuration',
      component: lazy(() => import('modules/Mail/MailTemplate')),
    },
  ],
};

export default mail;
