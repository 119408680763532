import React, { useState } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { IconButton, PopoverOrigin, Tooltip } from '@material-ui/core';
import { Action } from 'types';
import { ActionMenu } from './ActionMenu';
import { Icon } from './Icon';

interface Props {
  anchorOrigin?: PopoverOrigin;
  badge?: string;
  badgeColor?: 'primary' | 'secondary' | 'default' | 'error';
  id: string;
  icon?: IconDefinition | string;
  items: Array<Action>;
  label?: string;
}

export function ActionMenuIconButton({
  anchorOrigin,
  badge,
  badgeColor,
  id,
  icon,
  items,
  label,
}: Props): React.ReactElement {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [anchorElement, setAnchorElement] = React.useState<HTMLElement | undefined>(undefined);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorElement(event.currentTarget);
    setMenuOpen(true);
  };
  const handleClose = (): void => {
    setAnchorElement(undefined);
    setMenuOpen(false);
  };

  if (!items || items.length === 0) {
    return <></>;
  }

  return (
    <>
      {label ? (
        <Tooltip title={label} enterDelay={300}>
          <IconButton onClick={handleOpen} color="inherit">
            <Icon badge={badge} badgeColor={badgeColor} icon={icon || 'moreVert'} />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton onClick={handleOpen} color="inherit">
          <Icon badge={badge} badgeColor={badgeColor} icon={icon || 'moreVert'} />
        </IconButton>
      )}
      <ActionMenu
        anchorElement={anchorElement}
        anchorOrigin={anchorOrigin}
        id={id}
        items={items}
        open={menuOpen}
        onClose={handleClose}
      />
    </>
  );
}

export default ActionMenuIconButton;
