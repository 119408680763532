import { lazy, Fragment } from 'react';
import { Module } from 'types';
import { faBars, faChartTreeMap, faLayerGroup, faGlobe } from '@fortawesome/pro-regular-svg-icons';
import DefaultLayout from 'layouts/DefaultLayout';

const contentManagement: Module = {
  name: 'ContentManagement',
  icon: 'pages',
  role: 'Creator',
  layout: DefaultLayout,
  featurePath: 'features',
  features: [
    {
      name: 'Website',
      component: lazy(() => import('modules/ContentManagement/features/Website')),
      icon: faGlobe,
    },
    {
      name: 'Page',
      component: lazy(() => import('modules/ContentManagement/features/Page')),
      layout: Fragment,
      icon: faLayerGroup,
    },
    {
      name: 'Content',
      component: lazy(() => import('modules/ContentManagement/features/Content')),
      layout: Fragment,
      icon: faChartTreeMap,
    },
    {
      name: 'Menu',
      component: lazy(() => import('modules/ContentManagement/features/Menu')),
      icon: faBars,
    },
    {
      name: 'ContainerType',
      group: 'configuration',
      // groupIcon: 'faGear',
      role: 'Administrator',
      component: lazy(() => import('modules/ContentManagement/features/ContainerType')),
    },
    {
      name: 'ElementType',
      group: 'configuration',
      role: 'Administrator',
      component: lazy(() => import('modules/ContentManagement/features/ElementType')),
    },
    {
      name: 'SectionType',
      group: 'configuration',
      role: 'Administrator',
      component: lazy(() => import('modules/ContentManagement/features/SectionType')),
    },
    {
      name: 'PageType',
      group: 'configuration',
      role: 'Administrator',
      component: lazy(() => import('modules/ContentManagement/features/PageType')),
    },
  ],
};

export default contentManagement;
