import Joi from 'joi';
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { useLoginMutation } from 'auth';
import Checkbox from 'components/Checkbox';
import PageTitle from 'components/PageTitle';
import SubmitButton from 'components/SubmitButton';
import { useSelector } from 'store';

interface FormProps {
  onForgotPassword?: () => void;
}

const validationSchema = Joi.object({
  username: Joi.string().required(),
  password: Joi.string().required(),
  acceptPrivacy: Joi.bool().invalid(false).required(),
});

const Login: React.FC<FormProps> = ({ onForgotPassword }) => {
  const [login, { error, isLoading }] = useLoginMutation();
  const configuration = useSelector((state) => state.configuration);
  const { t } = useTranslation('account');
  const { control, handleSubmit } = useForm<any>({
    mode: 'onBlur',
    resolver: joiResolver(validationSchema),
  });

  const defaults = configuration.auth;
  const errorCode = error && 'data' in error ? (error as any).data.error : undefined;

  // Handler
  const onSubmit = (data: any): void => {
    login(data);
  };
  const submit = (e: React.FormEvent<HTMLFormElement>): Promise<void> => handleSubmit(onSubmit)(e);

  return (
    <>
      <Helmet>
        <title>{t('Login.title')} | Admin</title>
      </Helmet>
      <Box pb={6}>
        <PageTitle title={t('Login.title')} subtitle={t('Login.text')} overline="Admin" />
      </Box>
      {errorCode && (
        <Box pb={3}>
          <Alert severity="error">{t(`Login.errors.${errorCode}`)}</Alert>
        </Box>
      )}
      <form onSubmit={submit} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="username"
              control={control}
              defaultValue={defaults && defaults.username}
              render={({ field, fieldState }) => (
                <TextField
                  required
                  label={t('Login.username')}
                  error={fieldState.invalid}
                  fullWidth
                  size="small"
                  variant="outlined"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="password"
              control={control}
              defaultValue={defaults && defaults.password}
              render={({ field, fieldState }) => (
                <TextField
                  required
                  type="password"
                  label={t('Login.password')}
                  error={fieldState.invalid}
                  fullWidth
                  size="small"
                  variant="outlined"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Checkbox name="acceptPrivacy" label={t('Login.acceptPrivacy')} control={control} />
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <SubmitButton label={t('Login.login')} inProgress={isLoading} />
              </Grid>
              <Grid item>
                <Button variant="text" onClick={onForgotPassword}>
                  {t('Login.forgotPassword')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

Login.propTypes = {
  onForgotPassword: PropTypes.func,
};

export default Login;
