import { formatDuration as _formatDuration } from 'lib/utils';
import { UnitType } from 'metadata/enum';

/** Format duration to string. */
export function formatDuration(
  value: number | string,
  unitType: UnitType = UnitType.Millisecond
): string {
  if (!value) return '0';

  let ms = 0;
  let showMs = false;
  switch (unitType) {
    case UnitType.Nanosecond:
      ms = Number(value) / (1000 * 1000);
      showMs = true;
      break;
    case UnitType.Microsecond:
      ms = Number(value) / 1000;
      showMs = true;
      break;
    case UnitType.Millisecond:
      ms = Number(value);
      showMs = true;
      break;
    case UnitType.Second:
      ms = Number(value) * 1000;
      break;
    case UnitType.Minute:
      ms = Number(value) * 1000 * 60;
      break;
    case UnitType.Hour:
      ms = Number(value) * 1000 * 60 * 60;
      break;
    case UnitType.Day:
      ms = Number(value) * 1000 * 60 * 60 * 24;
      break;
  }

  return _formatDuration(ms, showMs);
}
