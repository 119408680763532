import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Switch, Route as RouterRoute } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { RouteContextProvider } from 'contexts/RouteContext';
import LoadingScreen from 'components/LoadingScreen';
import ComponentError from 'components/ComponentError';
import RoleGuard from 'components/RoleGuard';
import { Route, RouteWithContext } from './Route';

export const renderRoutes = (
  routes: Array<Route | RouteWithContext> = [],
  defaultLayout: React.ElementType = React.Fragment
): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Layout = route.layout || defaultLayout;
        const Component = route.component;
        const hasContext = Object.prototype.hasOwnProperty.call(route, 'context');
        const ContextProvider = (
          hasContext ? RouteContextProvider : React.Fragment
        ) as React.ElementType;
        const contextProps = hasContext
          ? { context: (route as RouteWithContext).context }
          : undefined;

        return (
          <RouterRoute
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <RoleGuard role={route.role}>
                <ContextProvider {...contextProps}>
                  <Layout>
                    {route.title && (
                      <Helmet>
                        <title>{route.title}</title>
                      </Helmet>
                    )}
                    {route.children ? (
                      renderRoutes(route.children)
                    ) : (
                      <ErrorBoundary FallbackComponent={ComponentError}>
                        {route.component && <Component {...route.props} {...props} />}
                      </ErrorBoundary>
                    )}
                  </Layout>
                </ContextProvider>
              </RoleGuard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);
